import {css} from 'styled-components'

export const inlineElementStyles = css`
  u,
  ins {
    text-decoration: underline;
  }
  strong,
  b {
    font-weight: bold;
  }
  i,
  cite,
  em,
  var,
  address,
  dfn {
    font-style: italic;
  }
  tt,
  code,
  kbd,
  samp {
    font-family: monospace;
  }
  pre,
  xmp,
  plaintext,
  listing {
    display: block;
    font-family: monospace;
    white-space: pre;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  mark {
    background-color: yellow;
    color: black;
  }
  big {
    font-size: 1.2em;
  }
  small {
    font-size: 0.8em;
  }
  s,
  strike,
  del {
    text-decoration: line-through;
  }
  sub {
    vertical-align: sub;
    font-size: smaller;
  }
  sup {
    vertical-align: super;
    font-size: smaller;
  }
  nobr {
    white-space: nowrap;
  }
`