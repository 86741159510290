import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgLogoInsta from '../generated/svg/logo-insta'
import { Link } from 'react-router-dom'
import SvgLogo from '../generated/svg/logo'
import SvgDeleteButton from '../generated/svg/delete-button'

const Outer = styled.div`
  background-color: #140404d8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
`
const Inner = styled.div`
  text-align: center;
  margin-top: 2rem;
`

const Logo = styled(Link)`
  

`
const DeleteButton = styled.div`
  position: absolute;
  top: 2rem;
  right: 1.38rem;
  cursor: pointer;
`

const LabelOuter = styled.div`
  margin: 6rem 0 4rem;
`

const Label = styled(Link)`
  display: block;
  font-size: 1.5625rem;
  color: #fff;
  margin-bottom: 2rem;
`

const Insta = styled.a``

type MenuProps = {
onClose:()=>void
}
export const Menu: FunctionComponent<MenuProps> = ({onClose}) => {
  return <Outer>
    <Inner>
      <Logo onClick={onClose} to="/">
        <SvgLogo/>
      </Logo>
      <DeleteButton onClick={onClose}>
        <SvgDeleteButton/>
      </DeleteButton>
          <LabelOuter>
            <Label onClick={onClose} to="/#kodawari">こだわり</Label>
            <Label onClick={onClose} to="/#news">ニュース</Label>
            <Label onClick={onClose} to="/#menu">お食事</Label>
            <Label onClick={onClose} to="/#restaurant">お店</Label>
            <Label onClick={onClose} to="/#takeout">テイクアウト</Label>
            <Label onClick={onClose} to="/#access">アクセス・お問合せ</Label>
            <Insta onClick={onClose} href='https://www.instagram.com/gyuya0528/' target='_blank'>
              <SvgLogoInsta/>
            </Insta>
          </LabelOuter>
        </Inner>
  </Outer>
}