import * as React from "react";
import type { SVGProps } from "react";
const SvgLogoInsta = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33.5}
    height={34.07}
    viewBox="0 0 33.5 34.07"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="\u9577\u65B9\u5F62_235"
          fill="none"
          d="M0 0h33.5v34.07H0z"
          data-name="\u9577\u65B9\u5F62 235"
        />
      </clipPath>
    </defs>
    <g id="\u30B0\u30EB\u30FC\u30D7_23" data-name="\u30B0\u30EB\u30FC\u30D7 23">
      <g
        id="\u30B0\u30EB\u30FC\u30D7_266"
        fill="#fff"
        clipPath="url(#clip-path)"
        data-name="\u30B0\u30EB\u30FC\u30D7 266"
      >
        <path
          id="\u30D1\u30B9_1727"
          d="M23.866 0H9.622A9.625 9.625 0 0 0 0 9.63v14.814a9.621 9.621 0 0 0 9.622 9.626h14.244a9.629 9.629 0 0 0 9.63-9.626V9.63A9.633 9.633 0 0 0 23.866 0M3.417 9.63a6.214 6.214 0 0 1 6.2-6.21h14.249a6.215 6.215 0 0 1 6.21 6.21v14.814a6.215 6.215 0 0 1-6.21 6.21H9.622a6.214 6.214 0 0 1-6.2-6.21Z"
          data-name="\u30D1\u30B9 1727"
          transform="translate(.004)"
        />
        <path
          id="\u30D1\u30B9_1728"
          d="M10.454 19.289a8.775 8.775 0 1 0-8.775-8.775 8.787 8.787 0 0 0 8.775 8.775m0-14.814a6.039 6.039 0 1 1-6.043 6.039 6.04 6.04 0 0 1 6.043-6.039"
          data-name="\u30D1\u30B9 1728"
          transform="translate(6.298 6.52)"
        />
        <path
          id="\u30D1\u30B9_1729"
          d="M6.979 1.271a1.965 1.965 0 1 1-1.967 1.967 1.965 1.965 0 0 1 1.967-1.967"
          data-name="\u30D1\u30B9 1729"
          transform="translate(18.801 4.768)"
        />
      </g>
    </g>
  </svg>
);
export default SvgLogoInsta;
