import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useDishesQueryQuery } from '../generated/graphql'
import { isNotNullish } from '../utils/type-check'
import classNames from 'classnames'

const Outer = styled.div`
`


const Text = styled.div`
  color: #62311B;
  font-size: 1.625rem;
  text-align: center;
  margin: 14rem 0 8rem;

  @media (max-width: 40rem){
    margin: 14rem 0 4rem;
  
  }
`

const MenuOuter = styled.div`
  max-width: 62.625rem;
  width: 100%;
  gap: 1.5rem;
  margin: 0 auto 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 3rem;
  padding: 0 2rem;
  box-sizing: border-box;

  @media (max-width: 40rem){
    grid-template-columns: 1fr;
    gap: 1rem;
    row-gap: 3rem;
    padding: 0 0 5rem;
  }
`


const MenuBox = styled.div`
  &.large{
    grid-column: span 3;
    @media (max-width: 40rem){
      grid-column: span 1;
    }
  }
`

const LargeBox = styled.div`
  display: flex;
  max-width: 100%;
  gap: 1.5rem;

  @media (max-width: 40rem){
    gap: 1rem;
    flex-wrap: wrap;
  }
`

const Img = styled.img`
  display: block;
  height: auto;
  flex: 1 1 0;
  width: 0;
  @media (max-width: 40rem){
    flex: 0 0 100%;
  }
`

const Menu = styled.div`
  color: #62311B;
  font-size: 1.25rem;
  margin-top: 0.5rem;
  line-height: 2rem;

  @media (max-width: 40rem){
    text-align: center;
  }
`

type MenuPageProps = {

}
export const MenuPage: FunctionComponent<MenuPageProps> = () => {
  const dishesQuery = useDishesQueryQuery()
  const dishes = dishesQuery.data?.dishes?.nodes?.filter(isNotNullish)
  return <Outer>
    <Text>ぎゅーやのおすすめ料理</Text>
    <MenuOuter>
      {
        dishes?.map(dish => {
          return <MenuBox className={classNames({large: dish.dishfields?.img2})}>
            <LargeBox>
              <Img src={dish.dishfields?.img1?.mediaItemUrl ?? ''} />
              {
                dish.dishfields?.img2 && 
                <Img src={dish.dishfields?.img2?.mediaItemUrl ?? ''} />
              }
            </LargeBox>
            <Menu>{dish.title}</Menu>
          </MenuBox>
        })
      }
    </MenuOuter>
  </Outer>
}