import * as React from "react";
import type { SVGProps } from "react";
const SvgPagetopMobile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={63.992}
    height={63.992}
    viewBox="0 0 63.992 63.992"
    {...props}
  >
    <g
      id="\u30B0\u30EB\u30FC\u30D7_303"
      data-name="\u30B0\u30EB\u30FC\u30D7 303"
      transform="translate(-19 -656.008)"
    >
      <g
        id="\u30B0\u30EB\u30FC\u30D7_282"
        data-name="\u30B0\u30EB\u30FC\u30D7 282"
      >
        <path
          id="\u30D1\u30B9_1783"
          fill="rgba(255,255,255,0.8)"
          d="M32 0A32 32 0 1 1 0 32 32 32 0 0 1 32 0Z"
          data-name="\u30D1\u30B9 1783"
          transform="translate(19 656.008)"
        />
        <path
          id="\u30D1\u30B9_1785"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={3}
          d="m7960.065 843.245 17.6-26.8 17.6 26.8"
          data-name="\u30D1\u30B9 1785"
          transform="translate(-7926.671 -141.843)"
        />
      </g>
    </g>
  </svg>
);
export default SvgPagetopMobile;
