import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgLogo from '../generated/svg/logo'
import { Link } from 'react-router-dom'

const Outer = styled.div`
  background-color: #2E0D0D;
  font-family: serif;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
`

const Inner = styled.div`
  max-width: 61.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 7rem 2rem 5rem;
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;

  @media (max-width: 50rem){
    display: none;
  }
`

const Logo = styled(Link)`
  width: 4.8125rem;
  height: auto;
  position: absolute;
  left: 0;
  transition: opacity 0.4s;
  &:hover{
    opacity: 0.7;
  }
`

const LabelOuter = styled.div`
  display: flex;
  gap: 1.5rem;
`

const Label = styled(Link)`
  color: #fff;
  font-size: 1rem;
  position: relative;
  &:nth-child(-n+5):after {
    position: absolute;
    content: "";
    width: 0.0625rem;
    top: 15%;
    height: 80%;
    background-color: #fff;
    margin-left: 0.75rem;
  }
  transition: opacity 0.4s;
  &:hover{
    opacity: 0.7;
  }
`
const Text = styled.div`
  font-size: 0.6875rem;
  color: #fff;
  padding-bottom: 2rem;
  text-align: center;

  @media (max-width: 50rem){
    padding: 3rem 0;
  }
`

type FooterProps = {

}
export const Footer: FunctionComponent<FooterProps> = () => {
  return <Outer>
    <Inner>
      <Logo to="/">
        <SvgLogo/>
      </Logo>
      <LabelOuter>
        <Label to="/#kodawari">こだわり</Label>
        <Label to="/news">ニュース</Label>
        <Label to="/menu">お食事</Label>
        <Label to="/#restaurant">お店</Label>
        <Label to="/#takeout">テイクアウト</Label>
        <Label to="/#access">アクセス・お問合せ</Label>
      </LabelOuter>
    </Inner>
    <Text>© 2023 GYU-YA. All rights reserved.</Text>
  </Outer>
}