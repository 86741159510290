import * as React from "react";
import type { SVGProps } from "react";
const SvgMenuButton = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48.014}
    height={37.235}
    viewBox="0 0 48.014 37.235"
    {...props}
  >
    <g
      id="\u30B0\u30EB\u30FC\u30D7_280"
      fill="none"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeWidth={3}
      data-name="\u30B0\u30EB\u30FC\u30D7 280"
      transform="translate(-347.833 -15.398)"
    >
      <path
        id="\u30D1\u30B9_1780"
        d="M8417.348 160.9h-45.014"
        data-name="\u30D1\u30B9 1780"
        transform="translate(-8023.001 -144)"
      />
      <path
        id="\u30D1\u30B9_1781"
        d="M8417.348 160.9h-45.014"
        data-name="\u30D1\u30B9 1781"
        transform="translate(-8023.001 -126.882)"
      />
      <path
        id="\u30D1\u30B9_1782"
        d="M8417.348 160.9h-45.014"
        data-name="\u30D1\u30B9 1782"
        transform="translate(-8023.001 -109.765)"
      />
    </g>
  </svg>
);
export default SvgMenuButton;
