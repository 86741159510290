import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import SvgNews from '../generated/svg/news'
import { usePostsQuery } from '../generated/graphql'
import { isNotNullish } from '../utils/type-check'
import dayjs from 'dayjs'

const Outer = styled.div`
  font-family: serif;
  scroll-margin-top: 12rem;
`

const SectionName = styled.div`
  color: #62311B;
  max-width: 62.625rem;
  margin: 10rem auto 5rem;
  padding: 0 2rem;

  @media (max-width: 40rem){
    max-width: 12.4375rem;
    margin: 6rem auto;
  }
`
const NewsBox = styled.div`
  padding: 0 2rem;

  @media (max-width: 40rem){
    padding: 1.5rem 1rem;
  }
`

const NewsOuter = styled(Link)`
  max-width: 51.875rem;
  display: flex;
  margin: 0 auto;
  gap: 2rem;
  border-top: 0.09rem solid #140404;
  transition: opacity 0.4s;

  padding: 3rem 2rem;
  &:hover {
    opacity: 0.8;
  }
  &:last-child {
    border-bottom: 0.09rem solid #140404;
  }

  @media (max-width: 40rem){
    padding: 3rem 0;
  }
`

const ImgOuter = styled.div`
  position: relative;
  flex: 0 0 auto;
  img {
    max-width: 100%;
    width: 14.25rem;
    height: auto;

    @media (max-width: 40rem){
      width: 10.75rem;
    }
  }
`


const TextOuter = styled.div`
  color: #140404;
`

const Date = styled.div`
  font-size: 1.1875rem;
  margin-bottom: 1rem;
`

const News = styled.div`
  font-size: 1.4375rem;
  line-height: 2.5rem;
  font-weight: 700;
  @media (max-width: 40rem){
    color: #62311B;
    font-size: 0.9375rem;
    line-height: 2;
  }
`

const Button = styled(Link)`
  color: #62311B;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.625rem;
  width: 16.1875rem;
  height: 4rem;
  background-color: #fff;
  border-radius: 2rem;
  border: 0.06rem solid #62311B;
  margin: 7rem auto 10rem;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background-color: #2E0D0D;
    color: #fff;
  }

  @media (max-width: 40rem){
    margin: 0 auto 6rem;
  }
`
const New = styled.div`
  position: absolute;
  width: 4.5rem;
  height: 4.5rem;
  color: #fff;
  border-radius: 2.25rem;
  background-color: #E01A1A;
  top: -2.25rem;
  left: -2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1875rem;

  @media (max-width: 40rem){
    left: -0.9rem;
    font-size: 1.2rem;
  }
`

type TopPageNewsSectionProps = {

}
export const TopPageNewsSection: FunctionComponent<TopPageNewsSectionProps> = () => {
  const postsQuery = usePostsQuery()
  const posts = postsQuery.data?.posts?.nodes?.filter(isNotNullish)
  return <Outer id="news">
    <SectionName>
      <SvgNews/>
    </SectionName>
    <NewsBox>
      {
        posts?.map(post => {
          return <NewsOuter key={post.postId} to={`/news/${post.postId}`}>
            <ImgOuter>
              <img src={post.postFields?.thumbnail?.mediaItemUrl ?? undefined} width={post.postFields?.thumbnail?.mediaDetails?.width ?? undefined} height={post.postFields?.thumbnail?.mediaDetails?.height ?? undefined}/>
              {
                post.postFields?.isnew &&
                <New>NEW</New>
              }
            </ImgOuter>
            <TextOuter>
              <Date>{dayjs(post.date ?? '').format('YYYY.MM.DD')}</Date>
              <News dangerouslySetInnerHTML={{__html: post.title ?? ''}}></News>
            </TextOuter>
          </NewsOuter>
        })
      }
    </NewsBox>
    <Button to="/news">詳しくはこちら</Button>
  </Outer>
}