import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgKodawari from '../generated/svg/kodawari'
import { useBreakpoint } from '../hooks/use-break-point'

const Outer = styled.div`
  font-family: serif;
  background-color: #AD8A5226;
  padding-top: 15rem;
`

const Inner = styled.div`
  max-width: 80rem;
  padding: 2rem;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr calc((min(62.5rem, 100%) - 1.5rem) * 0.4) 1.5rem calc((min(62.5rem, 100%) - 1.5rem) * 0.6) 1fr;
  grid-template-rows: auto;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 40rem){
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`

const LeftContainer = styled.div`
  grid-column: span 2;
  grid-row: 1;
  display: grid;
  grid-template-columns: subgrid;

  @media (max-width: 40rem){
    display: block;
  }
`

const RightContainer = styled.div`
  grid-column: 4 / span 2;
  grid-row: 1;
  display: grid;
  grid-template-columns: subgrid;
  grid-template-rows: auto;
`

const SectionName = styled.div`
  color: #62311B;
  max-width: 62.625rem;
  margin: 0 auto 3rem;
  padding: 0 2rem;

  @media (max-width: 40rem){
    max-width: 12.4375rem;
    margin: 0 auto;
  }
`

const TextOuter = styled.div`
  grid-column: 2;
`

const Text1 = styled.div`
  color: #62311B;
  font-size: 1.625rem;
  line-height: 3.5rem;
  margin-bottom: 3rem;

  @media (max-width: 40rem){
    font-size: 1.5rem;
    padding: 0 1rem;
    margin: 3rem 0 1rem;
  }
`

const Text2 = styled.div`
  color: #140404;
  font-size: 1.1875rem;
  line-height: 3rem;

  @media (max-width: 40rem){
    padding: 0 1rem;
  }
`

const ImageContainer1 = styled.div`
  grid-column: span 2;
  margin-top: -8rem;
  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @media (max-width: 40rem){
    margin-top: 1rem;
    width: 17.625rem;
  }
`

const ImageContainer2 = styled.div`
  grid-column: 1 / span 2;
  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @media (max-width: 40rem){
    margin-bottom: 1rem;
  }
`

const ImageContainer3 = styled.div`
  grid-column: 1;
  margin-top: 4rem;
  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @media (max-width: 40rem){
    width: 17.6875rem;
    margin-left: auto;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }
`


type TopPageKodawariSectionProps = {

}
export const TopPageKodawariSection: FunctionComponent<TopPageKodawariSectionProps> = () => {
  const isMobile =useBreakpoint("40rem")
  return <Outer id="kodawari">
    <SectionName>
      <SvgKodawari/>
    </SectionName>
    <Inner>
      <LeftContainer>
        <TextOuter>
          <Text1>
            新鮮なお肉とこだわりの料理。<br/>
            心温まる雰囲気を大事にした<br/>
            小料理屋「ぎゅーや」。
          </Text1>
          {
            isMobile&&<ImageContainer2>
            <img src="/images/toppage_kodawari1.jpg" />
          </ImageContainer2>
          }
          <Text2>新鮮なお肉と脂の乗ったプリプリのもつが入った「ぎゅーや」自慢のもつ鍋を心をこめて仕上げ、その他にも手作りの心温まる料理をお楽しみください。</Text2>
        </TextOuter>
        <ImageContainer1>
          <img src="/images/toppage_kodawari2.jpg" />
        </ImageContainer1>
      </LeftContainer>
      <RightContainer>
        {
          isMobile||<ImageContainer2>
          <img src="/images/toppage_kodawari1.jpg" />
        </ImageContainer2>
        }
        <ImageContainer3>
          <img src="/images/toppage_kodawari3.jpg" />
        </ImageContainer3>
      </RightContainer>
    </Inner>
  </Outer>
}