import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopPageAccessSection } from '../sections/top-page-access-section'
import { TopPageBannerSection } from '../sections/top-page-banner-section'
import { TopPageKodawariSection } from '../sections/top-page-kodawari-section'
import { TopPageMenuSection } from '../sections/top-page-menu-section'
import { TopPageNewsSection } from '../sections/top-page-news-section'
import { TopPageRestaurantSection } from '../sections/top-page-restaurant-section'
import { TopPageTakeoutSection } from '../sections/top-page-takeout-section'

const Outer = styled.div``

type TopPageProps = {

}
export const TopPage: FunctionComponent<TopPageProps> = () => {
  return <Outer>
    <TopPageBannerSection />
    <TopPageKodawariSection />
    <TopPageNewsSection />
    <TopPageMenuSection />
    <TopPageRestaurantSection />
    <TopPageTakeoutSection />
    <TopPageAccessSection />
  </Outer>
}