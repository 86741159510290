import React, { FunctionComponent } from 'react'
import styled from 'styled-components'


const Outer = styled.div`
`

const Img1 = styled.img`
  width: 80rem;
  max-width: 100%;
  display: block;
  margin: 14rem auto 0;

  @media (max-width: 53.125rem){
    display: none;
  }
`

const Img2 = styled.img`
  display: none;

  @media (max-width: 53.125rem){
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
`

type TopPageBannerSectionProps = {

}
export const TopPageBannerSection: FunctionComponent<TopPageBannerSectionProps> = () => {
  return <Outer>
    <Img1 src="/images/toppage1.png"></Img1>
    <Img2 src="/images/toppage_mobile.png"></Img2>
  </Outer>
}