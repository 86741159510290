import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgMise from '../generated/svg/mise'

const Outer = styled.div`
  font-family: serif;
  background-color: #AD8A5226;
  padding: 10rem 0;
  scroll-margin-top: 2rem;

  @media (max-width: 40rem){
    padding: 2rem 0;
  }
`

const SectionName = styled.div`
  color: #62311B;
  max-width: 62.625rem;
  margin: 0 auto 3rem;
  padding: 0 2rem;

  @media (max-width: 40rem){
    max-width: 6.0625rem;
    margin: 2rem auto 4rem;
  } 
`
const FlexOuter = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;

  @media (max-width: 40rem){
    flex-direction: column;
    gap: 0;
  }
`

const BoxL = styled.div`
  flex: 6 0 0;
  text-align: right;
`

const Img1 = styled.img`
  width: 44.4375rem;
  max-width: 100%;

  @media (max-width: 40rem){
    display: none;
  }
`

const BoxR = styled.div`
  flex: 4 0 0;
  min-width: 0;
`

const TextOuter = styled.div`
  max-width: 100%;
  width: 23.9375rem;
  margin-bottom: 4.8rem;

  @media (max-width: 40rem){
    width: 100%;
    margin-bottom: 2rem;
  }
`

const Text1 = styled.div`
  max-width: 100%;
  font-size: 1.625rem;
  color: #62311B;
  line-height: 3.5rem;
  margin-bottom: 3rem;

  @media (max-width: 40rem){
    margin: 0;
    padding: 0 1rem;
  }
`

const Text2 = styled.div`
  max-width: 100%;
  color: #140404;
  font-size: 1.1875rem;
  line-height: 3rem;

  @media (max-width: 40rem){
    padding: 0 1rem;
  }
`

const Img2 = styled.img`
  max-width: 100%;
  width: 23.9375rem;

  @media (max-width: 40rem){
    display: none;
    width: 100%;

  }
`

const Img3 = styled.img`
  display: none;

  @media (max-width: 40rem){
    display: block;
    width: 100%;
    height: auto;
    margin: 2rem 0;
  }
`

const Img4 = styled.img`
  display: none;

  @media (max-width: 40rem){
    display: block;
    width: 100%;
    height: auto;
    margin: 2rem auto 6rem;
    padding: 0 1rem;
    box-sizing: border-box;
  }
`

type TopPageRestaurantSectionProps = {

}
export const TopPageRestaurantSection: FunctionComponent<TopPageRestaurantSectionProps> = () => {
  return <Outer id="restaurant">
    <SectionName>
      <SvgMise/>
    </SectionName>
    <FlexOuter>
      <BoxL>
        <Img1 src="images/toppage_mise1.png"></Img1>
      </BoxL>
      <BoxR>
        <TextOuter>
          <Text1>江戸川区・葛西の路地裏に<br/>ひっそりと店を構える<br/>小料理屋「ぎゅーや」。</Text1>
          <Img3 src="/images/toppage_mise_mobile1.jpg"></Img3>
          <Text2>カウンター5席とテーブル16席の落ち着きある和風の店内で、気負わずに過ごせる居心地の良さが当店の魅力。いつでもふらりとお立ち寄りください。</Text2>
        </TextOuter>
        <Img4 src="/images/toppage_mise_mobile2.png"></Img4>
        <Img2 src="images/toppage_mise2.jpg"></Img2>
      </BoxR>
    </FlexOuter>
  </Outer>
}