import * as React from "react";
import type { SVGProps } from "react";
const SvgDeleteButton = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36.072}
    height={36.072}
    viewBox="0 0 36.072 36.072"
    {...props}
  >
    <g
      id="\u30B0\u30EB\u30FC\u30D7_301"
      data-name="\u30B0\u30EB\u30FC\u30D7 301"
      transform="translate(-332.458 -33.381)"
    >
      <g
        id="\u30B0\u30EB\u30FC\u30D7_280"
        data-name="\u30B0\u30EB\u30FC\u30D7 280"
        transform="rotate(-45 382.172 68.484)"
      >
        <path
          id="\u30D1\u30B9_1781"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth={3}
          d="M8417.348 160.9h-45.014"
          data-name="\u30D1\u30B9 1781"
          transform="translate(-8023.001 -126.882)"
        />
      </g>
      <g
        id="\u30B0\u30EB\u30FC\u30D7_298"
        data-name="\u30B0\u30EB\u30FC\u30D7 298"
        transform="rotate(-135 364.77 47.137)"
      >
        <path
          id="\u30D1\u30B9_1781-2"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth={3}
          d="M8417.348 160.9h-45.014"
          data-name="\u30D1\u30B9 1781"
          transform="translate(-8023.001 -126.882)"
        />
      </g>
    </g>
  </svg>
);
export default SvgDeleteButton;
