import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgAccess from '../generated/svg/access'
import SvgLogo from '../generated/svg/logo'
import SvgAccessMobile from '../generated/svg/access-mobile'
import { useBreakpoint } from '../hooks/use-break-point'

const Outer = styled.div`
  font-family: serif;
  padding: 7rem 0;
  background-color: #A7A7A726;
  scroll-margin-top: 2rem;

  @media (max-width: 40rem){
    padding: 4rem 0 2rem;
  }
`

const SectionName = styled.div`
  color: #62311B;
  margin: 4rem auto;
  max-width: 62.625rem;
  padding: 0 2rem;

  @media (max-width: 40rem){
    display: none;
  }
`
const SectionNameMobile = styled.div`
  display: none;

  @media (max-width: 40rem){
    display: block;
    text-align: center;
    margin-bottom: 4rem;
  }
`

const Map = styled.div`
  width: 100%;
  max-width: 66.625rem;
  box-sizing: border-box;
  height: 25.125rem;
  margin: 0 auto;
  padding: 0 2rem;
  iframe {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 40rem){
    padding: 0 1rem;
  }
`
const BoxOuter = styled.div`
  max-width: 62.625rem;
  display: flex;
  margin: 3rem auto;
  padding: 0 2rem;

  @media (max-width: 40rem){
    flex-direction: column;
    margin: 2rem auto;
    padding: 0 1rem;
  }
`

const BoxL = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: 40rem){
    width: 100%;
  }
`

const TextOuter = styled.div`
  width: 100%;
`

const Name = styled.div`
  color: #62311B;
  font-size: 1.875rem;
  margin-bottom: 1rem;
`

const Address = styled.div`
  color: #140404;
  font-size: 1.1875rem;
  margin-bottom: 2rem;
`

const Access = styled.div`
  color: #140404;
  font-size: 1rem;
  line-height: 2rem;
`

const Img = styled.img`
  width: 30.375rem;
  max-width: 100%;
  height: auto;
  margin-top: auto;

  @media (max-width: 40rem){
    width: 100%;
    height: auto;
  }
`

const BoxR = styled.div`
  margin-left: 3rem;
  width: 40%;

  @media (max-width: 40rem){
    width: 100%;
    margin:  2rem 0;
  }
`

const DetailBox = styled.div`
  padding: 1rem 0;
  border-top: 0.1rem solid #140404;
  line-height: 2rem;
  &:nth-child(n+2) {
   display: flex;
  }
  &:last-child {
   border-bottom: 0.1rem solid #140404;
  }
`

const Detail1 = styled.div`
  color: #140404;
  font-size: 1rem;
  margin-bottom: 1rem;
`

const Detail2 = styled.a`
  display: block;
  font-size: 3.3125rem;
  color: #62311B;
  margin-bottom: 0.5rem;
  transition: opacity 0.4s;
  &:hover{
    opacity: 0.7;
  }

  @media (max-width: 25rem){
    font-size: 2.8rem;
  }
`

const Detail3 = styled.div`
  color: #140404;
  font-size: 1rem;
  max-width: 6rem;
  width: 100%;
`

const Detail4 = styled.div`
  color: #140404;
  font-size: 1rem;
`

const Logo = styled.div`
  display: none;

  @media (max-width: 40rem){
    display: block;
    width: 6.75rem;
    margin: 0 auto;
  }
`


type TopPageAccessSectionProps = {

}
export const TopPageAccessSection: FunctionComponent<TopPageAccessSectionProps> = () => {
  const isMobile = useBreakpoint("40rem")
  return <Outer id="access">
    <SectionName>
      <SvgAccess/>
    </SectionName>
    <SectionNameMobile>
      <SvgAccessMobile/>
    </SectionNameMobile>
    <Map>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.5029995455798!2d139.86848368277282!3d35.66461477823532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601887dd74406fd1%3A0x91ffab550b4a5fef!2z44KC44Gk6Y2LIOOBjuOCheODvOOChA!5e0!3m2!1sen!2sus!4v1701315357169!5m2!1sen!2sus" width="600" height="450" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </Map>
    <BoxOuter>
      <BoxL>
        <TextOuter>
          <Name>ぎゅーや（GYU-YA）</Name>
          <Address>東京都江戸川区中葛西3-19-1</Address>
          <Access>交通：東京メトロ東西線　葛西駅　徒歩2分<br/>（中央口または、メトロセンター第二 連絡通路経由が便利です）　<br/>葛西駅から203m</Access>
        </TextOuter>
        {
          isMobile || <Img src="/images/toppage_access1.jpg"></Img>
        }
      </BoxL>
      <BoxR>
        <DetailBox>
          <Detail1>お問合せ・ご予約</Detail1>
          <Detail2 href='tel:0356794498'>03-5679-4498</Detail2>
        </DetailBox>
        <DetailBox>
          <Detail3>営業時間</Detail3>
          <Detail4>月曜日〜土曜日<br/>17:30〜23:00<br/>（LO 22:00 ドリンクLO 22:30）</Detail4>
        </DetailBox>
        <DetailBox>
          <Detail3>定休日</Detail3>
          <Detail4>日曜日 （ 臨時休業有 ）</Detail4>
        </DetailBox>
        <DetailBox>
          <Detail3>駐車場</Detail3>
          <Detail4>なし</Detail4>
        </DetailBox>
        <DetailBox>
          <Detail3>席数</Detail3>
          <Detail4>カウンター5席　テーブル16席</Detail4>
        </DetailBox>
        <DetailBox>
          <Detail3>貸切</Detail3>
          <Detail4>可（16名以上）<br/>※詳しくはご相談ください</Detail4>
        </DetailBox>
        <DetailBox>
          <Detail3>支払い方法</Detail3>
          <Detail4>クレジット可<br/>（VISA,Master,AMEX,JCB,Diners）<br/>電子決済　不可</Detail4>
        </DetailBox>
      </BoxR>
      {
          isMobile && <Img src="/images/toppage_access1.jpg"></Img>
        }
    </BoxOuter>
    <Logo>
      <SvgLogo/>
    </Logo>
  </Outer>
}