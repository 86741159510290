import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import SvgLogo from '../generated/svg/logo'
import SvgLogoInsta from '../generated/svg/logo-insta'
import { Link, useLocation } from 'react-router-dom'
import SvgMenuButton from '../generated/svg/menu-button'
import { Menu } from './menu'
import SvgLogoBlack from '../generated/svg/logo-black'
import { useBreakpoint } from '../hooks/use-break-point'

const Outer = styled.div`
  font-family: serif;
  background-color: #140404cc;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding: 1.5rem 0;
  @media (max-width: 53.125rem){
    position: absolute;
    padding: 2rem 0 ;
  }
`

const Inner = styled.div`
  max-width: 62.5rem;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  padding: 0 2rem;
  box-sizing: border-box;

  @media (max-width: 53.125rem){
    align-items: start;
}
`

const Logo = styled(Link)`
  width: 5.5625rem;
  height: auto;
  margin-right: auto;
  transition: opacity 0.4s;
  &:hover{
    opacity: 0.7;
  }
@media (max-width: 53.125rem){
  margin: 0 auto;
}

`

const MenuButton = styled.div`
  display: none;

  @media (max-width: 53.125rem){
    display: block;
    position: fixed;
    top: 2rem;
    right: 1rem;
    transition: opacity 0.4s;
    cursor: pointer;
    transform: scale(0.8);
    &:hover{
      opacity: 0.7;
    }
  }

`

const LabelOuter = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  @media (max-width: 53.125rem){
    display: none;
  }
`

const Label = styled(Link)`
  font-size: 1.0625rem;
  color: #fff;
  transition: opacity 0.4s;
  &:hover{
    opacity: 0.7;
  }
`

const Insta = styled.a`
  display: block;
  transition: opacity 0.4s;
  &:hover{
    opacity: 0.7;
  }
`

type HeaderProps = {

}
export const Header: FunctionComponent<HeaderProps> = () => {
  const [open, setOpen] = useState(false)
  const location=useLocation()
  const isToppage=location.pathname==='/'
  const isMobile=useBreakpoint('53.125rem')
  return <Outer style={{backgroundColor:isMobile&&!isToppage?'transparent':'#140404cc'}}>
    <Inner>
      <Logo to="/">
        {
          isMobile && !isToppage ? <SvgLogoBlack /> : <SvgLogo/>
        }
      </Logo>
      <LabelOuter>
        <Label to="/#kodawari">こだわり</Label>
        <Label to="/#news">ニュース</Label>
        <Label to="/#menu">お食事</Label>
        <Label to="/#restaurant">お店</Label>
        <Label to="/#takeout">テイクアウト</Label>
        <Label to="/#access">アクセス・お問合せ</Label>
        <Insta href='https://www.instagram.com/gyuya0528/' target='_blank'>
          <SvgLogoInsta/>
        </Insta>
      </LabelOuter>
    </Inner>
    <MenuButton onClick={()=>setOpen(true)}style={{color:isToppage?'white':'#411818'}}>
        <SvgMenuButton/>
    </MenuButton>
    {
      open && <Menu onClose={()=>setOpen(false)}/> 
    }
   
  </Outer>
}