import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgTakeout from '../generated/svg/takeout'
import { useBreakpoint } from '../hooks/use-break-point'

const Outer = styled.div`
  font-family: serif;
  padding: 15rem 0 10rem;
  scroll-margin-top: -2rem;
`

const Box = styled.div`
  display: flex;
  gap: 5rem;
  padding: 0 2rem;
  max-width: 62.625rem;
  margin: 0 auto;

  @media (max-width: 40rem){
    flex-direction: column;
    padding: 0;
  }
`

const SectionName = styled.div`
  color: #62311B;
  margin-bottom: 2rem;
 
 @media (max-width: 40rem){
   max-width: 20rem;
   margin: 0 auto 3rem;
 }
`

const BoxL = styled.div`
  width: 29.0625rem;
  max-width: 100%;
  min-width: 0;
  
  @media (max-width: 40rem){
    width: 100%;
  }
`

const Text1 = styled.div`
  font-size: 1.625rem;
  color: #62311B;
  line-height: 3.5rem;
  margin-bottom: 2rem;

  @media (max-width: 40rem){
    padding: 0 1rem;
  }
`

const Img3 = styled.img`
  display: none;

  @media (max-width: 40rem){
    display: block;
    height: auto;
    margin-bottom: 2rem;
    max-width: 100%;
  }
`

const Text2 = styled.div`
  color: #140404;
  font-size: 1.1875rem;
  line-height: 3rem;
  margin-bottom: 2rem;

  @media (max-width: 40rem){
    padding: 0 1rem;
  }
`

const Text3 = styled.div`
  color: #140404;
  font-size: 1rem;
  line-height: 2rem;
  margin-bottom: 1rem;

  @media (max-width: 40rem){
    padding: 0 1rem;
  }
`

const BoxR = styled.div`
  width: 31.9375rem;
`

const Img1 = styled.img`
  display: block;
  margin: 2rem 0;
  width: 25.0625rem;
  max-width: 100%;

  @media (max-width: 40rem){
    display: none;
  }
`

const Img2 = styled.img`
  width: 31.9375rem;
  max-width: 100%;
  margin: 1rem 0;

  @media (max-width: 40rem){
    display: block;
    width: 16.4375rem;
    margin: 0 auto 2rem;
  }
`
const Text4 = styled.div`
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 2rem;

  @media (max-width: 40rem){
    padding: 0 1rem;
  }
`

type TopPageTakeoutSectionProps = {

}
export const TopPageTakeoutSection: FunctionComponent<TopPageTakeoutSectionProps> = () => {
  const isMobile = useBreakpoint("40rem")
  return <Outer id="takeout">
    <Box>
      <BoxL>
        <SectionName>
          <SvgTakeout/>
        </SectionName>
        <Text1>家庭でも味わえるように<br/>もつ鍋セットの販売を始めました</Text1>
        <Img3 src="/images/toppage_takeout_mobile1.jpg"></Img3>
        <Text2>盛り付けて火にかけるだけで、おうちで簡単に「ぎゅーや もつ鍋」を食べる事ができます。 興味のあるかたはスタッフにお声掛けください</Text2>
        {
          isMobile && <Img2 src= "images/toppage_takeout2.jpg"></Img2>
        }
        <Text3>【 商品情報 】</Text3>
        <Text3> ■ 2〜3人前　4,320円（税込）<br/>牛もつ（ 330g ）<br/>スープ（ 醤油味 または 辛味噌味 ）<br/>薬味（ 輪切り唐辛子 ）<br/>ちゃんぽん麺（ 2玉 ）</Text3>
        <Text3> ■ 4〜5人前　5,832円（税込）<br/>牛もつ（ 550g ）<br/>スープ（ 醤油味 または 辛味噌味 ）<br/>薬味（ 輪切り唐辛子 ）<br/>ちゃんぽん麺（ 3玉 ）</Text3>
        <Text3> ■ 追加牛もつ（ 100g ）　864円（税込）</Text3>
        <Text3> ■ 追加ちゃんぽん麺（ 1玉 ）　432円（税込）</Text3>
        <Text4>店舗でのお渡しか、出前館でのデリバリーのみの販売になります。商品がない場合がございますので事前にご予約ください</Text4>
      </BoxL>
      <BoxR>
        <Img1 src= "images/toppage_takeout1.jpg"></Img1>
        {
          isMobile || <Img2 src= "images/toppage_takeout2.jpg"></Img2>
        }
        
      </BoxR>
    </Box>
  </Outer>
}