import React from 'react';
import { FunctionComponent } from 'react';
import { ResetCss } from './components/reset-css';
import { RootCss } from './components/root-css';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ScrollToTop } from './components/scroll-to-top'
import { RestoreScroll } from './components/restore-scroll'
import { TopPage } from './pages/top-page'
import { NewsListPage } from './pages/news-list-page'
import { MenuPage } from './pages/menu-page'
import { Header } from './components/header';
import { Footer } from './components/footer';
import { styled } from 'styled-components';
import { Pagetop } from './components/page-top';

const apolloClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://gyu-ya-kasai.com/wp/index.php?graphql',
    fetchOptions: {
      mode: 'cors'
    }
  }),
  cache: new InMemoryCache()
})

const Outer = styled.div``

export const App: FunctionComponent = () => {
  return <>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <ScrollToTop />
        <RestoreScroll />
        <ResetCss />
        <RootCss />
        <Outer>
          <Pagetop/>
          <Header />
          <Routes>
            <Route index element={<TopPage />} />
            <Route path="news" element={<NewsListPage />}/>
            <Route path="news/:newsId" element={<NewsListPage />}/>
            <Route path="menu" element={<MenuPage />}/>
          </Routes>
          <Footer />
        </Outer>
      </BrowserRouter>
    </ApolloProvider>
  </>
}