import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgPagetop from '../generated/svg/pagetop'
import SvgPagetopMobile from '../generated/svg/pagetop-mobile'

const Outer = styled.div`
  font-family: serif;
  position: fixed;
  bottom: 2.5rem;
  left: 3.625rem;
  cursor: pointer;
  transition: opacity 0.4s;
  &:hover{
    opacity: 0.7;
  }
  z-index: 1;
  @media (max-width: 40rem){
    bottom: 0.9375rem;
    left: 1.1875rem;
  }
`

const Svg = styled.div`
  @media (max-width: 40rem){
    display: none;
  }
`

const Text = styled.div`
  color: #140404;
  font-size: 0.5625rem;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  margin-top: 0.5rem;

  @media (max-width: 40rem){
    display: none;
  }
`

const SvgMobile = styled.div`
  display: none;
  @media (max-width: 40rem){
    display: block;
    z-index:1;
  }
`

type PagetopProps = {

}
export const Pagetop: FunctionComponent<PagetopProps> = () => {
  return <Outer onClick={()=>{window.scrollTo({top:0 , behavior:"smooth",})}}>
    <Svg>
      <SvgPagetop/>
    </Svg>
    <Text>PAGE TOP</Text>
    <SvgMobile>
      <SvgPagetopMobile/>
    </SvgMobile>
  </Outer>
}