import * as React from "react";
import type { SVGProps } from "react";
const SvgPagetop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8.064}
    height={82.152}
    viewBox="0 0 8.064 82.152"
    {...props}
  >
    <path
      id="\u30D1\u30B9_16"
      fill="none"
      stroke="#140404"
      strokeWidth={1}
      d="M113.125 768.312v80.945l7.211-7.211"
      data-name="\u30D1\u30B9 16"
      transform="rotate(180 60.344 425.232)"
    />
  </svg>
);
export default SvgPagetop;
