import * as React from "react";
import type { SVGProps } from "react";
const SvgNewsPrev = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22.86}
    height={29.489}
    viewBox="0 0 22.86 29.489"
    {...props}
  >
    <g
      id="\u30B0\u30EB\u30FC\u30D7_296"
      data-name="\u30B0\u30EB\u30FC\u30D7 296"
      transform="rotate(-90 14.456 12.956)"
    >
      <path
        id="\u30D1\u30B9_1785"
        fill="none"
        stroke="#411818"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M0 19.282 12.667 0l12.666 19.282"
        data-name="\u30D1\u30B9 1785"
      />
    </g>
  </svg>
);
export default SvgNewsPrev;
