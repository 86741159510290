import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import SvgMenu from '../generated/svg/menu'

const Outer = styled.div`
  font-family: serif;
  padding: 3rem 0 ;
  background-color: #AD8A5226;
  scroll-margin-top: 6rem;

  @media (max-width: 40rem){
    padding: 3rem 0 1rem;
  }
`
const TextOuter = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 2rem;
  margin: 3rem auto;
  max-width: 62.625rem;
  padding: 0 2rem;

  @media (max-width: 40rem){
    flex-direction: column;
    margin: 2rem 0;
    gap: 4rem;
    align-items: start;
    padding: 0 1rem;
  }
`

const SectionName = styled.div`
  color: #62311B;

  @media (max-width: 40rem){
    max-width: 12.4375rem;
    margin: 0 auto;
  }
`

const Text = styled.div`
  color: #140404;
  font-size: 1.625rem;

  @media (max-width: 40rem){
    color: #62311B;
  }
`

const ImgOuter = styled.div`
  width: 100%;
  max-width: 80rem;
  margin: 4rem auto;

  @media (max-width: 40rem){
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }
`

const Img = styled.img`
  width: calc(100% / 3);
  height: auto;
  vertical-align: bottom;

@media (max-width: 40rem){
  width: 50%;
}
`

const Button = styled(Link)`
  color: #62311B;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.625rem;
  width: 16.1875rem;
  height: 4rem;
  background-color: #fff;
  border-radius: 2rem;
  border: 0.06rem solid #62311B;
  margin: 7rem auto;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background-color: #2E0D0D;
    color: #fff;
  }

  @media (max-width: 40rem){
    margin: 2rem auto;
  }
`
type TopPageMenuSectionProps = {

}
export const TopPageMenuSection: FunctionComponent<TopPageMenuSectionProps> = () => {
  return <Outer id="menu">
    <TextOuter>
      <SectionName>
        <SvgMenu/>
      </SectionName>
      <Text>「ぎゅーや」自慢の料理</Text>
    </TextOuter>
    <ImgOuter>
      <Img src="/images/toppage_menu1.jpg"></Img>
      <Img src="/images/toppage_menu2.jpg"></Img>
      <Img src="/images/toppage_menu4.jpg"></Img>
      <Img src="/images/toppage_menu3.jpg"></Img>
      <Img src="/images/toppage_menu5.jpg"></Img>
      <Img src="/images/toppage_menu6.jpg"></Img>
    </ImgOuter>
    <Button to="/menu">詳しくはこちら</Button>
  </Outer>
}