import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { usePostsQuery } from '../generated/graphql'
import { inlineElementStyles } from '../styles/inline-element-styles'
import { isNotNullish } from '../utils/type-check'
import dayjs from 'dayjs'
import { useParams } from 'react-router'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import SvgNewsPrev from '../generated/svg/news-prev'
import SvgNewsNext from '../generated/svg/news-next'
import classNames from 'classnames'

const Outer = styled.div``


const Text = styled.div`
  color: #62311B;
  font-size: 1.625rem;
  text-align: center;
  margin: 14rem 0 8rem;

  @media (max-width: 40rem){
    margin: 14rem 0 4rem;
  }
`

const ListBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 10rem;
  align-items: stretch;

  @media (max-width: 40rem){
    margin-bottom: 0;
  }
`
const YearBox = styled.div`
@media (max-width: 40rem){
  display: none;
}
`

const YearList = styled.div`
  min-width: 0;
  padding: 0 2rem;
  position: sticky;
  left: 0;
  top: 14.0625rem;
`

const Year = styled(Link)`
  font-size: 1.1875rem;
  color: #140404;
  margin-bottom: 2rem;
  transition: opacity 0.4s;
  display: block;
  &:hover{
    opacity: 0.7;
  }
`

const NewsOuter = styled.div`
  max-width: 41rem;
  min-width: 0;
  padding: 0 1rem;
`
const NewsBox = styled.div`
  border-top: 0.1rem solid #140404;
  padding-top: 4rem;
  &:last-child {
    border-bottom: 0.1rem solid #140404;

    @media (max-width: 40rem){
      border-bottom: none;
    }
  }
`

const PostDate = styled.div`
  font-size: 1.1875rem;
  color: #140404;
  margin-bottom: 2rem;

  @media (max-width: 40rem){
    margin-bottom: 1.5rem;
  }
`

const NewsTitle = styled.div`
  font-size: 1.25rem;
  color: #62311B;
  margin-bottom: 5rem;
  line-height: 2;

  @media (max-width: 40rem){
    margin-bottom: 3rem;
  }
`

const Content = styled.div`
  font-size: 1rem;
  color: #140404;
  margin-bottom: 2rem;
  line-height: 2;
  img {
    width: 40.625rem;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
  ${inlineElementStyles}
`
const NewsSerect = styled.div`
  display: none;

  @media (max-width: 40rem){
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin-bottom: 10rem;
  }
`

const Prev = styled(Link)`
  display: flex;
  align-items: center;
  gap: 1rem;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`

const Next = styled(Link)`
  display: flex;
  align-items: center;
  gap: 1rem;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`

const Text2 = styled.div`
  font-size: 1.0625rem;
  font-weight: 700;
`

const NewsBox2 = styled.div`
  display: none;
  @media (max-width: 40rem){
    display: block;
    padding: 2rem 1rem;
    background-color: #AD8A5226;
  }
` 

const NewsOuter2 = styled(Link)`
  @media (max-width: 40rem){
  max-width: 51.875rem;
  display: flex;
  margin: 0 auto;
  gap: 2rem;
  border-bottom: 0.09rem solid #140404;
  transition: opacity 0.4s;
  padding: 3rem 0;
  &:hover {
    opacity: 0.8;
  }
  }
`

const ImgOuter = styled.div`
  @media (max-width: 40rem){
  position: relative;
  flex: 0 0 auto;
  img {
    max-width: 100%;
    height: auto;
    width: 10.75rem;
    }
  }
`


const TextOuter = styled.div`
  @media (max-width: 40rem){
    color: #140404;
  }
 
`

const Date = styled.div`
  @media (max-width: 40rem){
    font-size: 1.1875rem;
  margin-bottom: 1rem;
  }
  
`

const News = styled.div`
@media (max-width: 40rem){
  line-height: 2.5rem;
  color: #62311B;
  font-size: 0.9375rem;
  }
`
const New = styled.div`
  @media (max-width: 40rem){
  position: absolute;
  width: 4.5rem;
  height: 4.5rem;
  color: #fff;
  border-radius: 2.25rem;
  background-color: #E01A1A;
  top: -2.25rem;
  left: -0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  }
`

// const PageNumber = styled.div`
//   display: none;
  
//   @media (max-width: 40rem){
//     display: block;
//     text-align: center;
//     font-size: 1.1875rem;
//     padding-top: 5rem;
//   }
// `

// const getPrev = <T extends any>(arr: T[], target: T): T | undefined => {
//   const index = arr.indexOf(target)
//   if(isNotNullish(index)){
//     return arr[index - 1]
//   } else  {
//     return
//   }
// }

// const getNext = <T extends any>(arr: T[], target: T): T | undefined => {
//   const index = arr.indexOf(target)
//   if(isNotNullish(index)){
//     return arr[index + 1]
//   } else  {
//     return
//   }

// }

type NewsListPageProps = {

}
export const NewsListPage: FunctionComponent<NewsListPageProps> = () => {
  const {newsId} = useParams<'newsId'>()
  const {year} = queryString.parse(location.search)
  
  const postsQuery = usePostsQuery()
  const posts = postsQuery.data?.posts?.nodes?.filter(isNotNullish) ?? []
  const filteredPosts = posts.filter(post => {
    if(isNotNullish(newsId)){
      return post.postId === Number(newsId)
    }
    if(isNotNullish(year)){
      return dayjs(post.date).isSame(Array.isArray(year) ? year[0] : year, 'year')
    }
    return true
  })
  const postIndex = posts.findIndex(p => isNotNullish(newsId) && p.postId === Number(newsId))
  const prevPost = posts[postIndex - 1]
  const nextPost = posts[postIndex + 1]
  console.log(prevPost, nextPost)
  return <Outer>
    <Text>ぎゅーやの最新ニュース</Text>
    <ListBox>
      <YearBox>
      <YearList>
        {
          posts?.reduce((arr, post) => {
            const year = dayjs(post.date ?? '').get('year')
            if(arr.includes(year)){
              return arr
            } else {
              arr.push(year)
              arr.sort()
              return arr
            }
          }, [] as number[]).map(year => {
            return <Year key={year} to={`/news?year=${year}`}>{year}</Year>
          })
        }
      </YearList>
      </YearBox>
      <NewsOuter>
        {
          filteredPosts?.map(post => {
            return <NewsBox key={post.postId}>
              <PostDate>{dayjs(post.date ?? '').format('YYYY.MM.DD')}</PostDate>
              <NewsTitle dangerouslySetInnerHTML={{__html: post.title ?? ''}}/>
              <Content dangerouslySetInnerHTML={{__html: post.content ?? ''}}/>
            </NewsBox>
          })
        }
      </NewsOuter>
    </ListBox>
    {
      isNotNullish(newsId) && <NewsSerect>
        <Prev to={prevPost ? `/news/${prevPost.postId}` : ''} className={classNames({disabled: !prevPost})}>
          <Text2>前のニュース</Text2>
          <SvgNewsPrev/>
        </Prev>
        <Next to={nextPost ? `/news/${nextPost.postId}` : ''} className={classNames({disabled: !nextPost})}>
          <SvgNewsNext/>
          <Text2>次のニュース</Text2>
        </Next>
      </NewsSerect>
    }
    <NewsBox2>
      {
        posts?.map(post => {
          return <NewsOuter2 key={post.postId} to={`/news/${post.postId}`}>
            <ImgOuter>
              <img src={post.postFields?.thumbnail?.mediaItemUrl ?? undefined} width={post.postFields?.thumbnail?.mediaDetails?.width ?? undefined} height={post.postFields?.thumbnail?.mediaDetails?.height ?? undefined}/>
              {
                post.postFields?.isnew &&
                <New>NEW</New>
              }
            </ImgOuter>
            <TextOuter>
              <Date>{dayjs(post.date ?? '').format('YYYY.MM.DD')}</Date>
              <News dangerouslySetInnerHTML={{__html: post.title ?? ''}}></News>
            </TextOuter>
          </NewsOuter2>
        })
      }
    </NewsBox2>
    
  </Outer>
}